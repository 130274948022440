.page_container[data-v-7fd152d1] {
  float: right;
  margin-top: 10px;
}
.search-right[data-v-7fd152d1] {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.search-right .search-mine span[data-v-7fd152d1] {
  margin-right: 10px;
  cursor: pointer;
}
.search-right .search-mine span.active[data-v-7fd152d1] {
  color: var(--themeColor);
}
